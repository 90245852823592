'use client';

import React from 'react';
import type { RegisterProps } from 'components/commercetools-ui/organisms/authentication/register';
import Register from 'components/commercetools-ui/organisms/authentication/register';
import type { TasticProps } from 'frontastic/tastics/types';

const AccountRegisterTastic = ({ data }: TasticProps<RegisterProps>) => {
    return <Register {...data} />;
};

export default AccountRegisterTastic;
