import React from 'react';
import type { LinkReference } from 'types/reference';
import RegisterForm from './register-form';

export interface RegisterProps {
    privacyPolicy: string;
    individualDescriptionMarkdown: string;
    businessDescriptionMarkdown: string;
    loginRedirectContent: string;
    loginRedirectButtonText: string;
    corporateQualificationRedirectLink: LinkReference;
    individualDescriptionMarkdown2: string;
    employerSponsoredDescription: string;
    employerRegistrationDescription: string;
    groupBookingsDescription: string;
    selfFundedEnabled: boolean;
    employerSponsoredEnabled: boolean;
    employerRegistrationEnabled: boolean;
    groupBookingsEnabled: boolean;
    chatLinkEnabled: boolean;
    chatLinkText: string;
    selfFundedTileLabel: string;
    employerRegistrationTileLabel: string;
    employerSponsoredTileLabel: string;
    groupBookingsTileLabel: string;
}

const Register: React.FC<RegisterProps> = ({
    privacyPolicy,
    individualDescriptionMarkdown,
    businessDescriptionMarkdown,
    loginRedirectButtonText,
    loginRedirectContent,
    corporateQualificationRedirectLink,
    individualDescriptionMarkdown2,
    employerSponsoredDescription,
    employerRegistrationDescription,
    groupBookingsDescription,
    selfFundedEnabled,
    employerSponsoredEnabled,
    employerRegistrationEnabled,
    groupBookingsEnabled,
    chatLinkEnabled,
    chatLinkText,
    selfFundedTileLabel,
    employerRegistrationTileLabel,
    employerSponsoredTileLabel,
    groupBookingsTileLabel
}) => {
    return (
        <div className="bg-checkout-bg px-16  pb-20 pt-14 lg:pb-150 lg:pt-60">
            <div className="m-auto grid max-w-[530px] bg-white p-20 lg:p-30">
                <RegisterForm
                    privacyPolicy={privacyPolicy}
                    individualDescription={individualDescriptionMarkdown}
                    individualDescription2={individualDescriptionMarkdown2}
                    businessDescription={businessDescriptionMarkdown}
                    loginRedirectContent={loginRedirectContent}
                    loginRedirectButtonText={loginRedirectButtonText}
                    corporateQualificationRedirectLink={corporateQualificationRedirectLink}
                    employerSponsoredDescription={employerSponsoredDescription}
                    employerRegistrationDescription={employerRegistrationDescription}
                    groupBookingsDescription={groupBookingsDescription}
                    selfFundedEnabled={selfFundedEnabled}
                    employerSponsoredEnabled={employerSponsoredEnabled}
                    employerRegistrationEnabled={employerRegistrationEnabled}
                    groupBookingsEnabled={groupBookingsEnabled}
                    chatLinkEnabled={chatLinkEnabled}
                    chatLinkText={chatLinkText}
                    selfFundedTileLabel={selfFundedTileLabel}
                    employerRegistrationTileLabel={employerRegistrationTileLabel}
                    employerSponsoredTileLabel={employerSponsoredTileLabel}
                    groupBookingsTileLabel={groupBookingsTileLabel}
                />
            </div>
        </div>
    );
};

export default Register;
