import type { FC } from 'react';
import React, { useState, useMemo, useEffect } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import Button from 'components/commercetools-ui/atoms/button';
import Modal from 'components/commercetools-ui/atoms/modal';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic';
import AccountFieldsForm, { AccountFieldsFormType } from '../../account/account-atoms/account-fields-form';
import Markdown from '../../markdown';
import type { LinkReference } from 'types/reference';

interface Props {
    privacyPolicy?: string;
    individualDescription?: string;
    businessDescription?: string;
    loginRedirectContent: string;
    loginRedirectButtonText: string;
    individualDescription2?: string;
    corporateQualificationRedirectLink?: LinkReference;
    employerSponsoredDescription?: string;
    groupBookingsDescription?: string;
    employerRegistrationDescription?: string;
    selfFundedEnabled: boolean;
    employerSponsoredEnabled: boolean;
    employerRegistrationEnabled: boolean;
    groupBookingsEnabled: boolean;
    chatLinkEnabled: boolean;
    chatLinkText: string;
    selfFundedTileLabel: string;
    employerRegistrationTileLabel: string;
    employerSponsoredTileLabel: string;
    groupBookingsTileLabel: string;
}

const RegisterForm: FC<Props> = ({
    privacyPolicy,
    individualDescription,
    businessDescription,
    loginRedirectContent,
    loginRedirectButtonText,
    individualDescription2,
    corporateQualificationRedirectLink,
    employerSponsoredDescription,
    groupBookingsDescription,
    employerRegistrationDescription,
    selfFundedEnabled,
    employerSponsoredEnabled,
    employerRegistrationEnabled,
    groupBookingsEnabled,
    chatLinkEnabled,
    chatLinkText,
    selfFundedTileLabel,
    employerRegistrationTileLabel,
    employerSponsoredTileLabel,
    groupBookingsTileLabel
}) => {
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

    const searchParams = useSearchParams();
    const existing = searchParams.get('existing');

    const pageTitle = !existing
        ? formatAccountMessage({ id: 'become.member', defaultMessage: 'Create account' })
        : formatAccountMessage({ id: 'welcome.back', defaultMessage: 'Welcome back!' });

    const title = formatAccountMessage({ id: 'personal.details', defaultMessage: 'Personal details' });

    //account actions
    const { loggedIn, logout } = useAccount();

    const [isOpen, setIsOpen] = useState(false);

    const router = useRouter();

    const [redirectToLogin, setRedirectToLogin] = useState(false);

    function handleRedirectToLogin(data: boolean) {
        setRedirectToLogin(data);
    }

    const handleLogout = async () => {
        await logout();
    };

    useEffect(() => {
        if (redirectToLogin) {
            void handleLogout();

            setIsOpen(true);
        }
    }, [redirectToLogin]);

    const closeRedirectModal = () => {
        setIsOpen(false);
        router.replace('/login');
    };

    //form submission

    const lvp = searchParams.get('lvp');

    const redirectLink = useMemo(() => {
        let lastVisitedPage = lvp ? `/${lvp}` : '/account';

        if (redirectToLogin) {
            lastVisitedPage = '/register';
        }
        return lastVisitedPage;
    }, [lvp, redirectToLogin]);

    if (loggedIn) return <Redirect target={redirectLink} />;

    return (
        <>
            <h1 className="text-18">{pageTitle}</h1>
            {existing && (
                <p className="text-14">
                    {formatAccountMessage({
                        id: 'profile.complete.description',
                        defaultMessage: 'Your profile is missing some important details. Please take a moment to complete your profile.'
                    })}
                </p>
            )}
            <AccountFieldsForm
                type={AccountFieldsFormType.REGISTER}
                privacyPolicy={privacyPolicy}
                title={title}
                individualDescription={individualDescription}
                businessDescription={businessDescription}
                containerClassName="grid gap-12"
                setRedirectToLogin={handleRedirectToLogin}
                individualDescription2={individualDescription2}
                corporateQualificationRedirectLink={corporateQualificationRedirectLink}
                employerSponsoredDescription={employerSponsoredDescription}
                groupBookingsDescription={groupBookingsDescription}
                employerRegistrationDescription={employerRegistrationDescription}
                selfFundedEnabled={selfFundedEnabled}
                employerSponsoredEnabled={employerSponsoredEnabled}
                employerRegistrationEnabled={employerRegistrationEnabled}
                groupBookingsEnabled={groupBookingsEnabled}
                chatLinkEnabled={chatLinkEnabled}
                chatLinkText={chatLinkText}
                registrationFormTileLabels={{
                    selfFundedTileLabel,
                    employerRegistrationTileLabel,
                    employerSponsoredTileLabel,
                    groupBookingsTileLabel
                }}
            />

            <Modal
                isOpen={isOpen}
                className="relative w-[90%] rounded-md bg-white"
                style={{ content: { maxWidth: '545px' } }}
                closeTimeoutMS={200}
            >
                <div className="flex flex-col gap-[25px] p-[15px] text-base leading-6 md:gap-20 md:p-40">
                    <Markdown markdown={loginRedirectContent} className="markdown" />
                    <Button
                        data-cy="close-membership-modal"
                        variant="secondary"
                        size="full"
                        onClick={closeRedirectModal}
                        className="rounded-md md:rounded-lg"
                    >
                        {loginRedirectButtonText}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default RegisterForm;
